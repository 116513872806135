import { useGLTF } from '@react-three/drei'
import { useRef } from "react";
import { useFrame } from "@react-three/fiber";

export function Model(props) {
  const modelRef = useRef();
  const { nodes, materials } = useGLTF('/scene.gltf');

  useFrame(() => {
    modelRef.current.rotation.y += 0.01;
  });

  return (
    <group {...props} dispose={null} ref={modelRef}>
      <mesh geometry={nodes.Brain_Part_01_BRAIN_TEXTURE_blinn2_0.geometry} material={materials.BRAIN_TEXTURE_blinn2} />
      <mesh geometry={nodes.Brain_Part_02_BRAIN_TEXTURE_blinn2_0.geometry} material={materials.BRAIN_TEXTURE_blinn2} />
      <mesh geometry={nodes.Brain_Part_03_BRAIN_TEXTURE_blinn2_0.geometry} material={materials.BRAIN_TEXTURE_blinn2} />
      <mesh geometry={nodes.Brain_Part_04_BRAIN_TEXTURE_blinn2_0.geometry} material={materials.BRAIN_TEXTURE_blinn2} />
      <mesh geometry={nodes.Brain_Part_05_BRAIN_TEXTURE_blinn2_0.geometry} material={materials.BRAIN_TEXTURE_blinn2} />
      <mesh geometry={nodes.Brain_Part_06_BRAIN_TEXTURE_blinn2_0.geometry} material={materials.BRAIN_TEXTURE_blinn2} />
    </group>
  )
}

useGLTF.preload('/scene.gltf')
